import { CognitoUserSession } from 'amazon-cognito-identity-js'

export type AccountRequestType = {
  account_id: string
  user_id: string
}

export type InitialStateType = {
  CognitoUserSession: CognitoUserSession | null
  isAuthenticated: boolean
  PayinsList: string[]
  PayinsWithoutAccount: string[]
  ids: string[]
}

const initialSate: InitialStateType = {
  CognitoUserSession: null,
  isAuthenticated: false,
  PayinsList: [],
  PayinsWithoutAccount: [],
  ids: []
}

export default initialSate
