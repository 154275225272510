import React, { FC, useEffect, useState } from 'react'
import EventEmitter from 'eventemitter3'
import Spinner from './Spinner'
import './Spinner.scss'

export const spinnerEmitter = new EventEmitter()

const EventSpinner: FC = () => {
  const [loading, loadingSetter] = useState(false)
  const [textValue, textSetter] = useState('')

  useEffect(() => {
    spinnerEmitter.on('loading', (isLoading: boolean, text: string) => {
      loadingSetter(isLoading)
      textSetter(text)
    })
    return () => {
      spinnerEmitter.off('loading')
    }
  }, [])

  return <>{loading && <Spinner text={textValue} />}</>
}

export default EventSpinner
