import { createContext, Dispatch } from 'react'
import { ActionAccountType } from './AccountsListTypes '
import initialSate, { InitialStateType } from './initialState'
import { ActionType } from './LoginSuccessTypes'
import { ActionPayinType } from './PayinsListTypes'

interface ContextPropsInterface {
  state: InitialStateType
  dispatch: Dispatch<ActionType | ActionPayinType | ActionAccountType>
}

const Context = createContext<ContextPropsInterface>({
  // eslint-disable-next-line prettier/prettier
  dispatch: () => {},
  state: initialSate
})

export default Context
