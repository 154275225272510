import React, { FC, useEffect, useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import EventEmitter from 'eventemitter3'
import { EventErrorPopupPropsType, EventErrorPopupEmitterType } from './EventErrorPopup.types'

export const errorPopupEmitter = new EventEmitter()

const EventErrorPopup: FC<EventErrorPopupPropsType> = ({ showPopupAlert = false }) => {
  const [showAlert, showAlertSetter] = useState(false)
  const [errorMessage, errorMessageSetter] = useState('')
  const [alertTitle, alertTitleSetter] = useState('')
  const onConfirm = () => showAlertSetter(false)

  useEffect(() => {
    errorPopupEmitter.on('errorPopup', ({ showPopupErrorAlert, title, message }: EventErrorPopupEmitterType) => {
      showAlertSetter(showPopupErrorAlert)
      errorMessageSetter(message)
      alertTitleSetter(title)
    })
    return () => {
      errorPopupEmitter.off('errorPopup')
    }
  }, [])

  useEffect(() => {
    showAlertSetter(showPopupAlert)
  }, [showPopupAlert])

  return (
    <>
      {showAlert && (
        <SweetAlert
          danger
          title={alertTitle}
          btnSize="sm"
          confirmBtnText="  OK  "
          onConfirm={onConfirm}
          closeOnClickOutside
        >
          {errorMessage}
        </SweetAlert>
      )}
    </>
  )
}

export default EventErrorPopup
