import React, { FC } from 'react'
import PuffLoader from 'react-spinners/PuffLoader'
import './Spinner.scss'

interface CompProps {
  // eslint-disable-next-line react/require-default-props
  text?: string
}

const Spinner: FC<CompProps> = ({ text }) => (
  <div className="loading-background">
    <p>{text}</p>
    <PuffLoader size={100} color="#00164E" />
  </div>
)

export default Spinner
