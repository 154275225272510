export enum PayinsListConstType {
  PayinList = 'PAYINS_LIST'
}
export interface PayinsListInterface {
  type: PayinsListConstType
  payload: string[]
  payinsWithoutAccount: string[]
}

export type ActionPayinType = PayinsListInterface
