import { InitialStateType } from './initialState'
import { ActionType, LOGIN_SUCCESS } from './LoginSuccessTypes'
import { ActionPayinType, PayinsListConstType } from './PayinsListTypes'
import { AccountsListConstType, ActionAccountType } from './AccountsListTypes '

const reducer = (
  state: InitialStateType,
  action: ActionType | ActionPayinType | ActionAccountType
): InitialStateType => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return { ...state, isAuthenticated: true, CognitoUserSession: action.payload }
    case PayinsListConstType.PayinList:
      return { ...state, PayinsList: action.payload, PayinsWithoutAccount: action.payinsWithoutAccount }
    case AccountsListConstType.AccountsList:
      return { ...state, ids: action.payload }
    default:
      return state
  }
}

export default reducer
