import React, { FC } from 'react'
import { Navbar } from 'react-bootstrap'

const OpenHeader: FC = () => (
  <Navbar bg="dark" variant="dark">
    <Navbar.Brand>
      <b>M2CROWD - Dashboard Depósitos</b>
    </Navbar.Brand>
  </Navbar>
)

export default OpenHeader
