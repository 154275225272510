import React, { FC } from 'react'
import OpenHeader from 'components/common/OpenHeader'

const LoggedMenu: FC = ({ children }) => (
  <>
    <OpenHeader />
    {children}
  </>
)

export default LoggedMenu
