import React, { FC, useState } from 'react'
import { Button, Nav, Navbar } from 'react-bootstrap'
import SweetAlert from 'react-bootstrap-sweetalert'
import { Auth } from 'aws-amplify'

import { CognitoErrorType } from 'services/AWSAmplify'
import { ROOT_ROUTE, ACCOUNTS } from 'routes/AppRoutes.consts'
import './Header.scss'
import { spinnerEmitter } from '../Spinner'
import { errorPopupEmitter } from '../AlertPopup'

const Header: FC = () => {
  const [showLogoutAlert, showLogoutAlertSetter] = useState(false)
  const [showSuccessAlert, showSuccessAlertSetter] = useState(false)

  const onCloseSessionConfirm = () => {
    showLogoutAlertSetter(false)
    showSuccessAlertSetter(true)
  }

  const onCancel = () => showLogoutAlertSetter(false)

  const errorPopup = (error: unknown) => {
    const cognitoError = error as CognitoErrorType
    const errorMessage = cognitoError.message ? `${cognitoError.message}` : 'Por favor vuelve a intentarlo más tarde'
    errorPopupEmitter.emit('errorPopup', {
      showPopupErrorAlert: true,
      title: 'Error 😔',
      message: `Ha ocurrido un error al cerrar sesión: ${errorMessage}`
    })
  }

  const onConfirm = async () => {
    try {
      spinnerEmitter.emit('loading', true)
      await Auth.signOut()
      showSuccessAlertSetter(false)
      spinnerEmitter.emit('loading', false)
      window.location.pathname = ROOT_ROUTE
    } catch (error) {
      showSuccessAlertSetter(false)
      spinnerEmitter.emit('loading', false)
      errorPopup(error)
    }
  }

  return (
    <>
      <Navbar bg="dark" variant="dark" className="justify-content-between">
        <Navbar.Brand href="/">
          <b>M2CROWD - Dashboard Depósitos</b>
        </Navbar.Brand>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="/">Depositos</Nav.Link>
            <Nav.Link href={ACCOUNTS}>Cuentas</Nav.Link>
          </Nav>
          <Nav className="justify-content-end">
            <Button variant="outline-info" onClick={() => showLogoutAlertSetter(true)}>
              Logout
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      {showLogoutAlert && (
        <SweetAlert
          warning
          showCancel
          btnSize="sm"
          confirmBtnBsStyle="danger"
          title="Cerrar sesión"
          confirmBtnText="  OK  "
          cancelBtnText="Cancelar"
          onConfirm={onCloseSessionConfirm}
          onCancel={onCancel}
          closeOnClickOutside
          focusCancelBtn
        >
          ¿Está segur@ de cerrar sesión?
        </SweetAlert>
      )}
      {showSuccessAlert && (
        <SweetAlert
          success
          title="¡Hasta pronto!"
          btnSize="sm"
          confirmBtnText="  OK  "
          onConfirm={onConfirm}
          closeOnClickOutside
          timeout={2000}
        >
          ¡Te esperamos de vuelta!
        </SweetAlert>
      )}
    </>
  )
}

export default Header
