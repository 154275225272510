import React, { FC } from 'react'
import LoggedMenu from './LoggedMenu'
import NotLoggedMenu from './NotLoggedMenu'
import { MenuWrapperProps } from './MenuWrapper.types'

const MenuWrapper: FC<MenuWrapperProps> = ({ children, menuType }) => {
  const menuOptions = {
    loggedMenu: <LoggedMenu>{children}</LoggedMenu>,
    notLoggedMenu: <NotLoggedMenu>{children}</NotLoggedMenu>
  }
  return menuOptions[menuType]
}

export default MenuWrapper
