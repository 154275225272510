import React, {
  Attributes,
  ComponentClass,
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { Auth } from 'aws-amplify'
import { Redirect, Route } from 'react-router-dom'

import MenuWrapper from 'components/common/MenuWrapper'
import { Context } from 'store'
import { LOGIN_SUCCESS } from 'store/LoginSuccessTypes'
import { LOGIN_ROUTE } from '../../AppRoutes.consts'
import { AuthRoutesWrapperType } from './AuthRoutesWrapper.types'

const AuthRoutesWrapper: AuthRoutesWrapperType = ({ exact = false, path, component, menuType, needAuthentication }) => {
  const {
    state: { isAuthenticated },
    dispatch
  } = useContext(Context)
  const [isAuthenticating, isAuthenticatingSetter] = useState(true)

  const onLoad = useCallback(async () => {
    try {
      const user = await Auth.currentSession()
      dispatch({ type: LOGIN_SUCCESS, payload: user })
      isAuthenticatingSetter(false)
    } catch (error) {
      localStorage.clear()
      isAuthenticatingSetter(false)
    }
  }, [dispatch])

  useEffect(() => {
    void onLoad()
  }, [onLoad])

  const RouterComponent = useMemo(
    () =>
      (
        routerProps:
          | (Attributes & ComponentClass<any, any>)
          | (Attributes & FunctionComponent<any>)
          | (Attributes & ComponentClass<any>)
          | (Attributes & FunctionComponent<any>)
          | null
          | undefined
      ): JSX.Element =>
        (
          <MenuWrapper menuType={menuType} {...routerProps}>
            {React.createElement(component, routerProps)}
          </MenuWrapper>
        ),
    [component, menuType]
  )

  if (isAuthenticating) return <></>
  if (!needAuthentication) return <Route exact={exact} path={path} component={RouterComponent} />
  if (isAuthenticated) return <Route exact={exact} path={path} component={RouterComponent} />
  return <Redirect to={LOGIN_ROUTE} />
}

export default AuthRoutesWrapper
