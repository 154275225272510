import { CognitoUserSession } from 'amazon-cognito-identity-js'

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export type LoginSuccessConstType = typeof LOGIN_SUCCESS

export interface LoginSuccessInterface {
  type: LoginSuccessConstType
  payload: CognitoUserSession
}

export type ActionType = LoginSuccessInterface
