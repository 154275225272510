import React, { FC, useReducer } from 'react'
import { BrowserRouter } from 'react-router-dom'
import Amplify, { Auth } from 'aws-amplify'
import { withAuthenticator } from '@aws-amplify/ui-react'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@aws-amplify/ui-react/styles.css'

import { EventErrorPopup } from 'components/common/AlertPopup'
import { EventAlert } from 'components/common/Alert'
import Routes from './routes'
import { EventSpinner } from './components/common/Spinner'
import { reducer, initialSate, Context } from './store'
import { amplifyConfig, amplifyAuthConfig } from './services/AWSAmplify'

Amplify.configure(amplifyConfig)
Auth.configure({ oauth: amplifyAuthConfig })

const App: FC = () => {
  const [state, dispatch] = useReducer(reducer, initialSate)

  return (
    <>
      <EventSpinner />
      <EventErrorPopup />
      <EventAlert />
      <BrowserRouter>
        <Context.Provider value={{ state, dispatch }}>
          <Routes />
        </Context.Provider>
      </BrowserRouter>
    </>
  )
}

export default withAuthenticator(App)
