export const amplifyConfig = {
  aws_project_region: process.env.REACT_APP_COGNITO_REGION || '',
  aws_cognito_identity_pool_id: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID || '',
  aws_cognito_region: process.env.REACT_APP_COGNITO_REGION || '',
  aws_user_pools_id: process.env.REACT_APP_COGNITO_USER_POOL_ID || '',
  aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID || '',
  Auth: {
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID || '',
    region: process.env.REACT_APP_COGNITO_REGION || '',
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID || '',
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID || ''
  },
  API: {
    endpoints: [
      {
        name: 'm2crowd',
        endpoint: process.env.REACT_APP_API_M2CROWD || ''
      }
    ]
  }
}

export const amplifyAuthConfig = {
  domain: process.env.REACT_APP_COGNITO_DOMAIN || '',
  redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_SIGN_IN || '',
  redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT_SIGN_OUT || '',
  responseType: 'token'
}
