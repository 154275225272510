import React, { FC, Suspense } from 'react'
import { Redirect, Switch } from 'react-router-dom'

import { Spinner } from 'components/common/Spinner'
import LazyImport from './helpers/LazyImport'
import AuthRoutesWrapper from './helpers/AuthRoutesWrapper'
import { ROOT_ROUTE, LOGIN_ROUTE, ACCOUNTS } from './AppRoutes.consts'

const Home = LazyImport(async () => import('components/Home'))
const Login = LazyImport(async () => import('components/UserAuth/Login'))
const Accounts = LazyImport(async () => import('components/Accounts'))

const AppRoutes: FC = () => (
  <Suspense fallback={<Spinner />}>
    <Switch>
      <AuthRoutesWrapper
        exact={true}
        path={ROOT_ROUTE}
        component={Home}
        menuType="loggedMenu"
        needAuthentication={true}
      />
      <AuthRoutesWrapper path={LOGIN_ROUTE} component={Login} menuType="notLoggedMenu" needAuthentication={false} />
      <AuthRoutesWrapper path={ACCOUNTS} component={Accounts} menuType="loggedMenu" needAuthentication={true} />
      <Redirect to={LOGIN_ROUTE} />
    </Switch>
  </Suspense>
)

export default AppRoutes
