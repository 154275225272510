import React, { FC } from 'react'
import Header from 'components/common/Header'

const LoggedMenu: FC = ({ children }) => (
  <>
    <Header />
    {children}
  </>
)

export default LoggedMenu
