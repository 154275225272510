import React, { FC, useEffect, useState } from 'react'
import EventEmitter from 'eventemitter3'
import Alert from 'react-bootstrap/esm/Alert'
import { EventAlertEmitterType, EventAlertPropsType } from './EventAlert.types'

export const alertEmitter = new EventEmitter()

const EventAlert: FC<EventAlertPropsType> = ({ showMessageAlert = false }) => {
  const [show, ShowSetter] = useState(false)
  const [message, messageSetter] = useState('')
  const [title, titleSetter] = useState('')
  const [color, ColorSetter] = useState('danger')

  useEffect(() => {
    alertEmitter.on('alert', ({ showAlert, titleAlert, messageAlert, colorAlert }: EventAlertEmitterType) => {
      ShowSetter(showAlert)
      messageSetter(messageAlert)
      titleSetter(titleAlert)
      ColorSetter(colorAlert)
    })
    return () => {
      alertEmitter.off('alert')
    }
  }, [color])

  useEffect(() => {
    ShowSetter(showMessageAlert)
  }, [showMessageAlert])

  return (
    <>
      {show && (
        <Alert variant={color} onClose={() => ShowSetter(false)} dismissible>
          <Alert.Heading>{title}</Alert.Heading>
          <p>{message}</p>
        </Alert>
      )}
    </>
  )
}

export default EventAlert
